import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import { IconButton } from '@material-ui/core';
import PageTitle from "../../components/PageTitle";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import axios from 'axios'
import CircularIndeterminate from "../../components/Loading";




const columns = [


    { id: "name", label: "Country", minWidth: 70 },

    {
        id: "short_code",
        label: "Short Code",
        minWidth: 150,
        // align: "right",
        format: (value) => value.toLocaleString("en-US"),
    },
    { id: "currency", label: "Currency", minWidth: 150 },
    {
        id: "status",
        label: "Status",
        minWidth: 150,
        // align: "right",
        format: (value) => value.toFixed(2),
    },
];

const myStyle = {
    Bodi: {
        position: "absolute",
        padding: 20,
        minWidth: "80vw",
        marginTop: "3%vh",
        left: "18.4%",
        top: "32vh",
    },
    menu: {
        position: "relative",
        top: "2.6vh",
        right: "0.6vw",
        width: "5vw",
        marginLeft: "3vw",
    },
    view: {
        position: "relative",
        top: "3.2vh",
        marginLeft: "1.5vw",
        marginRight: "1.5vw",
    },
};




function Countries() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [apiData, setApiData] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        axios.get('https://pingup.ajebur.com/api/global/countries')
            .then(res =>
                setApiData(res.data.data),

            )
            .catch(err => console.log(err))
    }, [])




    return (
        <div>
            <PageTitle title="Countries" />
            <div style={myStyle.Bodi}>
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "20px",
                        // position: "absolute",
                        // top: "-10vh",
                    }}
                >
                    <TableContainer sx={{ maxHeight: 1000, maxWidth: 1500 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {apiData === '' ? (
                                    <CircularIndeterminate />) : apiData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.code}
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ width: 1000 }}
                                                            >

                                                                {value === 1.0 ?
                                                                    <Button color='success' variant="outlined">Active</Button>
                                                                    : value}

                                                            </TableCell>
                                                        );
                                                    })}

                                                    <div style={myStyle.view}>
                                                        <IconButton>
                                                            <Icons.RemoveRedEyeOutlined
                                                                style={{ fontSize: "17px" }}
                                                            />
                                                        </IconButton>
                                                    </div>


                                                </TableRow>
                                            );
                                        })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 200]}
                        component="div"
                        count={apiData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </div>
    );
}
export default Countries;