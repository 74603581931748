import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import PageTitle from "../../components/PageTitle";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { IconButton } from '@material-ui/core';
import axios from 'axios'


const columns = [


    {
        id: "name",
        label: "Name",
        minWidth: 150,
        align: 'left'
    },

    {
        id: "email",
        label: "Email",
        minWidth: 70,
        align: 'right',
        format: (value) => value.toLocaleString("en-US"),
    },

    { id: "mobile", label: "Mobile", minWidth: 70, align: 'right', },
    {
        id: "status",
        label: "Status",
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];

const myStyle = {
    Bodi: {
        position: "absolute",
        padding: 20,
        minWidth: "80vw",
        marginTop: "3%vh",
        left: "18.4%",
        top: "32vh",
    },
    menu: {
        position: "relative",
        top: "2.6vh",
        right: "0.6vw",
        width: "5vw",
        marginLeft: "3vw",
    },
    view: {
        position: "relative",
        top: "2vh",
        marginLeft: "0.5vw",
    }
};




function CreditCheck() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [apiData, setApiData] = useState([])






    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };









    useEffect(() => {
        const stateData = async () => {
            await axios.get('https://pingup.ajebur.com/api/admin/listing')
                .then(res => {
                    console.log(res.data.data)
                    setApiData(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                });
        }
        stateData()
    }, [])







    return (
        <div>
            <div style={myStyle.add}>
                <PageTitle title="Sub Admin List" />

            </div>
            <div style={myStyle.Bodi}>
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "20px",
                        // position: "absolute",
                        // top: "-10vh",
                    }}
                >
                    <TableContainer sx={{ maxHeight: 1000, maxWidth: 1400 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {apiData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.code}
                                            >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ width: 300 }}
                                                        >

                                                            {value === 1.0 ? <Button color='success' variant="outlined">Active</Button> : value}

                                                        </TableCell>
                                                    );
                                                })}

                                                <div style={myStyle.view}>
                                                    <IconButton


                                                    >
                                                        <Icons.Edit
                                                            style={{ fontSize: "17px" }}
                                                        />
                                                    </IconButton>
                                                </div>


                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 200]}
                        component="div"
                        count={apiData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </div>
    );
}
export default CreditCheck;
