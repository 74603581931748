import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Paper } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const formstyle = {

    position: 'relative',
    left: '38vw'
}


function CreateAdmin() {
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [mobile, setMobile] = useState()
    const [roles, setRoles] = useState()
    const [password, setPassword] = useState()
    const [des, setDes] = useState()




    console.log(roles)

    const onSubmit = (e) => {
        // e.preventDefault
        if (name === null || email === null) {

            toast.error('All fields are required', {
                position: 'top-center'
            })


            setName('')
            setEmail('')
            setDes('')
            setPassword('')
            setMobile('')
            setRoles('')

        } else {
            const formData = new FormData()
            formData.append('name', name)
            formData.append('email', email)
            formData.append('mobile', mobile)
            formData.append('roles', roles)
            formData.append('password', password)
            formData.append('designation', des)
            axios({
                method: 'POST',
                url: 'http://pingup.ajebur.com/api/create/admin',
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then(res => console.log(res))
                .catch(err => console.log(err))

            toast.success('Admin Created', {
                position: 'top-right'
            })



        }
    }


    return (

        <div>
            <PageTitle title="Create Admin" />
            <Paper sx={{ width: 800, padding: '20px', margin: 'auto 18%' }}>
                <form>

                    <div style={{ marginBottom: '5vh', }} >
                        <TextField
                            sx={{ mr: 5, width: 300 }}
                            id="outlined-basic"
                            label="Full Name"
                            error={name === ""}
                            helperText={name === "" ? 'Empty field!' : ' '}

                            type='text'
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            variant="outlined" />

                        <TextField sx={{ width: 400 }}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            error={email === ""}
                            helperText={email === "" ? 'Empty field!' : ' '}
                            id="outlined-basic" type='email'
                            label="Email" variant="outlined" />
                    </div>
                    <div style={{ marginBottom: '5vh' }}>

                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                sx={{ mr: 5, width: 300 }}
                                id="demo-simple-select"
                                error={roles === ""}
                                helperText={roles === "" ? 'Empty field!' : ' '}
                                value={roles}
                                label="Roles"
                                onChange={(e) => setRoles(e.target.value)}
                            >
                                <MenuItem value={2}> Finance Admin</MenuItem>
                                <MenuItem value={3}>Admin Assistant</MenuItem>

                            </Select>
                        </FormControl>


                        <TextField sx={{ width: 400 }} id="outlined-basic"
                            label="Designation" variant="outlined"
                            error={des === ""}
                            helperText={des === "" ? 'Empty field!' : ' '}
                            onChange={(e) => setDes(e.target.value)}
                            value={des} />
                    </div>
                    <div style={{ marginBottom: '5vh' }}>
                        <TextField sx={{ mr: 5, width: 300 }} id="outlined-basic"
                            label="Mobile Number" variant="outlined"
                            error={mobile === ""}
                            helperText={mobile === "" ? 'Empty field!' : ' '}
                            onChange={(e) => setMobile(e.target.value)}
                            value={mobile} />
                        <TextField id="outlined-basic" sx={{ width: 400 }} type="password"
                            label="Password" variant="outlined"
                            error={password === ""}
                            helperText={password === "" ? 'Empty field!' : ' '}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password} />
                    </div>
                    <Button onClick={onSubmit} color='success' sx={formstyle} variant="contained">Submit  </Button>
                </form>
            </Paper>


        </div>
    );
}

export default CreateAdmin;
