import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import PageTitle from "../../components/PageTitle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";

import axios from "axios";
import CircularIndeterminate from "../../components/Loading";

import { useState, useEffect } from "react";
//import classnames from "classnames";
import { Button } from "@mui/material";

import {
  // AppBar,
  // Toolbar,
  IconButton,
  // InputBase,
  // Menu,
  // MenuItem,
  // //Fab,
  // // Link
} from "@material-ui/core";
// import {
//   Menu as MenuIcon,
//   MailOutline as MailIcon,
//   NotificationsNone as NotificationsIcon,
//   Person as AccountIcon,
//   Search as SearchIcon,
//   Send as SendIcon,
//   ArrowBack as ArrowBackIcon,
// } from "@material-ui/icons";


const myStyle = {
  Bodi: {
    position: "absolute",
    padding: 20,
    minWidth: "80vw",
    marginTop: "3%vh",
    left: "20%",
    top: "32vh",
  },
  btnColor:{
    backgroundColor:"#8ABE1E"
  }
};

// useEffect(() => {
//   setLoginStatus(res.data.status);
// }, []);

function Category(props) {
  const [apidata, setApidata] = useState("");
  const [apidata2, setApidata2] = useState("");

  useEffect(() => {
    axios
      .get("https://pingup.ajebur.com/api/full-categories")
      .then((res) => {
        // console.log(res.data.data.maincat);
        setApidata(res.data.data.maincat);
        setApidata2(res.data.data.subcat);
        // console.log(res.data.data.maincat);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const Apimaincat = apidata.maincat;
  // console.log(apidata);
  // console.log(apidata2);

  const handleFilter = (data) => {
    // console.log(apidata.id===apidata2.category_id);
    const hookData = props.hookData;
    const positive_array = apidata2.filter(function (value) {
      return value.category_id === data;
    });
    hookData(positive_array);
    // console.log(positive_array);
    // console.log(data);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // const [notificationsMenu, setNotificationsMenu] = useState(null);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle title="Category management" />
        <TextField
          id="input-with-icon-textfield"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icons.SearchOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          style={{ position: "relative", top: "8vh" }}
        />
      </div>
      <div style={myStyle.Bodi}>
        <Paper
          sx={{
            width: "85%",
            overflow: "hidden",
            borderRadius: "20px",
            // position: "absolute",
            // top: "-10vh",
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    S/N
                  </TableCell>
                  <TableCell align="right">Category-color</TableCell>
                  <TableCell align="right">Category-Name</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apidata === "" ? (
                  <CircularIndeterminate />
                ) : (
                  apidata
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((apidata) => (
                    <TableRow
                      key={apidata.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{apidata.id}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          sx={myStyle.btnColor}>
                            {apidata.name}
                        </Button>
                      </TableCell>
                      <TableCell align="right">{apidata.name}</TableCell>
                      <TableCell align="right">
                        <Button
                        sx={myStyle.btnColor}
                          variant="contained"
                          color="primary"
                        // style={{ position: "relative", left: "23vw" }}
                        >
                          -:-
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <div
                          style={{
                            display: "flex",
                            // position: "relative",
                            // left: "7.5vw",
                          }}
                        >
                          <div>
                            <Link to="/app/Specialization">
                              <IconButton
                                onClick={() => handleFilter(apidata.id)}
                              >
                                <Icons.RemoveRedEyeOutlined
                                  sx={{ fontSize: 20 }}
                                />
                              </IconButton>
                           </Link>
                          </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 200]}
            component="div"
            count={apidata.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}
export default Category;
