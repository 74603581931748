import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import PageTitle from "../../components/PageTitle";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { IconButton } from "@material-ui/core";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CircularIndeterminate from "../../components/Loading";
import { Typography, Divider } from "@mui/material";
import { toast } from "react-toastify";





const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  //border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  width: 800,
  padding: "20px",
  //  margin: 'auto 18%'
};

const formstyle = {
  position: "relative",
  left: "43vw",
};

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 150,
    align: "left",
  },
  {
    id: "rate",
    label: "Request Charge",
    minWidth: 70,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "recharge", label: "Min Recharge", minWidth: 70, align: "right" },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

const myStyle = {
  Bodi: {
    position: "absolute",
    padding: 20,
    minWidth: "80vw",
    marginTop: "3%vh",
    left: "18.4%",
    top: "32vh",
  },
  menu: {
    position: "relative",
    top: "2.6vh",
    right: "0.6vw",
    width: "5vw",
    marginLeft: "3vw",
  },
  view: {
    position: "relative",
    top: "2vh",
    marginLeft: "0.5vw",
  },
  add: {
    display: "flex",
    justifyContent: "space-between",
  },
  credit: {
    position: "relative",
    top: "5vh",
  },
};

function CreditCheck() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [apiData, setApiData] = useState('');
  const [open, setOpen] = useState(false);
  const [rate, setRate] = useState(null);
  const [status, setStatus] = useState("");
  const [recharge, setRecharge] = useState(null);
  const [subId, setSubId] = useState(null);
  const [occ, setOcc] = useState("");

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSubmit = (e) => {
    // e.preventDefault
    setRate('')
    setRecharge('')


    if (rate === null || recharge === null) {
      toast.error('All fields are required', {
        position: 'top-center'
      })

    } else {

      const formData = new FormData();

      formData.append("rate", rate);
      formData.append("recharge", recharge);
      formData.append("id", subId);

      axios({
        method: "POST",
        url: "http://pingup.ajebur.com/api/credit/update",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));


      setOpen(false)

      toast.success('Credit Value updated', {
        position: 'top-right'
      })
    }




  };

  useEffect(() => {
    const stateData = async () => {
      await axios
        .get("https://pingup.ajebur.com/api/credit/defaults")
        .then((res) => {
          console.log(res.data.data);
          setApiData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    stateData();
  }, []);

  console.log(subId);

  return (
    <div>
      <div style={myStyle.add}>
        <PageTitle title="Credit Check" />
        <div style={myStyle.credit}>
          <Button
            color="success"
            onClick={() => history.push("/app/addcredit")}
          >
            Add Credit <Icons.CreditCard />
          </Button>
        </div>
      </div>
      <div style={myStyle.Bodi}>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "20px",
            // position: "absolute",
            // top: "-10vh",
          }}
        >
          <TableContainer sx={{ maxHeight: 1000, maxWidth: 1400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {apiData === '' ? <CircularIndeterminate /> : apiData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ width: 300 }}
                            >
                              {value === 1.0 ? (
                                <Button color="success" variant="outlined">
                                  Active
                                </Button>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}

                        <div style={myStyle.view}>
                          <IconButton
                            onClick={() => {
                              setSubId(row.id);
                              setOcc(row.name);
                              setOpen(true);
                            }}
                          >
                            <Icons.Edit style={{ fontSize: "17px" }} />
                          </IconButton>

                          <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={open}
                            onClose={() => setOpen(false)}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Fade in={open}>
                              <Box sx={style}>
                                <Box sx={{ padding: 1, marginBottom: "0.5vh" }}>
                                  <Typography>Edit Credit Value</Typography>
                                </Box>
                                <Divider sx={{ marginBottom: "3vh" }} />

                                <form>
                                  <div style={{ marginBottom: "5vh" }}>
                                    <TextField
                                      sx={{ mr: 5, width: 300 }}
                                      id="outlined-basic"
                                      disabled
                                      label="Occupation "
                                      defaultValue={occ}
                                      variant="outlined"
                                    />

                                    <TextField
                                      sx={{ width: 400 }}
                                      onChange={(e) => setRate(e.target.value)}
                                      value={rate}
                                      error={rate === ""}
                                      helperText={rate === "" ? 'Empty field!' : ' '}
                                      id="outlined-basic"
                                      label="Credit Value"
                                      variant="outlined"
                                    />
                                  </div>
                                  <div style={{ marginBottom: "5vh" }}>
                                    <FormControl>
                                      <InputLabel id="demo-simple-select-label">
                                        Status
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        sx={{ mr: 5, width: 300 }}
                                        id="demo-simple-select"
                                        value={status}
                                        label="Status"
                                        onChange={(e) =>
                                          setStatus(e.target.value)
                                        }
                                      >
                                        <MenuItem value={1}> Active</MenuItem>
                                        <MenuItem value={2}>Inactive</MenuItem>
                                      </Select>
                                    </FormControl>

                                    <TextField
                                      sx={{ width: 400 }}
                                      id="outlined-basic"
                                      label="Minimum Value"
                                      variant="outlined"
                                      error={recharge === ""}
                                      helperText={recharge === "" ? 'Empty field!' : ' '}
                                      onChange={(e) =>
                                        setRecharge(e.target.value)
                                      }
                                      value={recharge}
                                    />
                                  </div>
                                  <Button
                                    onClick={onSubmit}
                                    color="success"
                                    sx={formstyle}
                                    variant="contained"
                                  >
                                    Submit{" "}
                                  </Button>
                                </form>
                              </Box>
                            </Fade>
                          </Modal>
                        </div>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 200]}
            component="div"
            count={apiData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}
export default CreditCheck;
