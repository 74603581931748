import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
//import { Box } from "@material-ui/core";
//import Icon from "@mdi/react";


// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Verification from "../../pages/providers/Verification";
import Services from "../../pages/providers/Services";
import Addcredit from "../../pages/Finance/Addcredit";
import Category from "../../pages/Category/Category";
// import Typography from "../../pages/typography";

import Countries from '../../pages/country/Countries'
import City from '../../pages/country/City'
import Construction from '../../pages/Underconstruction/Construction'
import CreateAdmin from '../../pages/Settings/CreateAdmin'
import CreateCategory from '../../pages/Settings/CreateCategory'
import Subadmin from '../../pages/Settings/Subadmin'
import Provinces from '../../pages/country/Provinces'
import CreditCheck from '../../pages/Finance/CreditCheck'
import Income from '../../pages/Finance/Income'
import WalletHistory from '../../pages/Finance/WalletHistory'
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Specialization from "../../pages/Specialization/specialization";
import Banners from "../../pages/Banners/Banners";


import User from "../../pages/Customers/User"


import { useState } from "react";


// context
import { useLayoutState } from "../../context/LayoutContext";


function Layout(props) {

  var classes = useStyles();
  var layoutState = useLayoutState();
  const [hookholder, setHookholder] = useState('');

  const hookData = (data) => {
    setHookholder(data)
  };

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/verification" component={Verification} />
            <Route path='/app/state' component={Provinces} />
            <Route path='/app/city' component={City} />
            <Route path='/app/construction' component={Construction} />
            <Route path='/app/creditcheck' component={CreditCheck} />
            <Route path='/app/income' component={Income} />
            <Route path='/app/wallet' component={WalletHistory} />
            <Route path='/app/addcredit' component={Addcredit} />
            <Route path="/app/country" component={Countries} />
            <Route path="/app/createadmin" component={CreateAdmin} />
            <Route path="/app/createcat" component={CreateCategory} />
            <Route path="/app/subadmin" component={Subadmin} />
            <Route path="/app/services" component={Services} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/banners" component={Banners} />

            <Route path="/app/category" ><Category hookData={hookData} /></Route>
            <Route path="/app/specialization"  ><Specialization hookholder={hookholder} /></Route>
            <Route path="/app/users"  ><User hookholder={hookholder} /></Route>
            <Route path="/app/category" ><Category hookData={hookData} /></Route>
            <Route path="/app/specialization"  ><Specialization hookholder={hookholder} /></Route>
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/icons" component={Icons} />

            <Route path="/app/ui/icons" component={Icons} />

            <Route path="/app/ui/charts" component={Charts} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
