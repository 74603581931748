import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import PageTitle from "../../components/PageTitle";
import { useState, useEffect } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "@mui/material";
import { IconButton } from '@material-ui/core';
import axios from 'axios'
import { toast } from "react-toastify";
import CircularIndeterminate from "../../components/Loading";




const columns = [
    { id: "state_id", label: "Country", minWidth: 150 },

    { id: "created_at", label: "States", minWidth: 150 },

    {
        id: "name",
        label: "Cities",
        minWidth: 150,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
    },

    {
        id: "status",
        label: "Status",
        minWidth: 150,
        align: "right",
        format: (value) => value.toFixed(2),
    },
];
const myStyle = {
    Bodi: {
        position: "absolute",
        padding: 20,
        minWidth: "80vw",
        marginTop: "3%vh",
        left: "18.4%",
        top: "32vh",
    },
    menu: {
        position: "relative",
        top: "2.6vh",
        right: "0.6vw",
        width: "5vw",
        marginLeft: "3vw",
    },
    view: {
        position: "relative",
        top: "3.2vh",
        marginLeft: "3vw",
    },
};




function City() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [country, setCountry] = useState('')
    const [states, setStates] = useState('')
    const [apiData, setApiData] = useState([])
    const [statesApi, setStatesApi] = useState([])
    const [cityApi, setCityApi] = useState('')
    const [countryID, setCountryID] = useState(null);
    const [stateId, setStateId] = useState(null);



    // const availableState = apiData.find((c) => c.name === country)

    // console.log(availableState)



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        axios.get('https://pingup.ajebur.com/api/global/countries')
            .then(res =>
                setApiData(res.data.data),

            )
            .catch(err => console.log(err))
    }, [])




    useEffect(() => {
        const stateData = async () => {
            await axios.get(`https://pingup.ajebur.com/api/global/states/${countryID}`)
                .then(res => {
                    // setStateId(res.data.data.id)
                    setStatesApi(res.data.data)
                    //console.log(res.data.data.id)
                })
                .catch(err => {
                    console.log(err)
                });
        }
        stateData()
    }, [countryID])



    useEffect(() => {
        const cityData = async () => {
            await axios.get(`https://pingup.ajebur.com/api/global/lga/${stateId}`)
                .then(res => {
                    console.log(res.data)
                    setCityApi(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                });
        }
        cityData()
    }, [stateId])




    const alert = useEffect(() => {
        toast.success('Please Select Country and State', {
            position: 'top-center'
        })
    }, [])


    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <PageTitle title="Cities" />
                <form>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={country}
                            label="Country"
                            onChange={(e) => setCountry(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {apiData?.map((con, index) => (<MenuItem onClick={() => setCountryID(con.id)} value={con.name} key={index}>{con.name}</MenuItem>))}

                        </Select>

                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label">States</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={states}
                            label="States"
                            onChange={(e) => setStates(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {statesApi?.map((sta, index) => (<MenuItem onClick={() => setStateId(sta.id)} value={sta.name} key={index}>{sta.name}</MenuItem>))}

                        </Select>

                    </FormControl>
                </form>



            </div>
            <div style={myStyle.Bodi}>
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "20px",
                        // position: "absolute",
                        // top: "-10vh",
                    }}
                >
                    <TableContainer sx={{ maxHeight: 700, width: '100%' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cityApi === '' ? alert : stateId === null ? <CircularIndeterminate /> : cityApi
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.code}
                                            >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ width: 500 }}
                                                        >


                                                            {value === 1.0 ? <Button color='success' variant="outlined">Active</Button> :
                                                                value && value === stateId ? country :
                                                                    value === null ? states :
                                                                        value}




                                                            {/* {column.format && typeof value === "number"
                                                                ? column.format(value)
                                                                : value} */}
                                                        </TableCell>
                                                    );
                                                })}
                                                <div style={{ display: "flex" }}>
                                                    <div style={myStyle.view}>
                                                        <Icons.RemoveRedEyeOutlined
                                                            style={{ fontSize: "17px" }}
                                                        />
                                                    </div>
                                                    <div style={myStyle.menu}>
                                                        <IconButton>
                                                            <Icons.DeleteOutline />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 200]}
                        component="div"
                        count={statesApi.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </div>
    );
}
export default City;