import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import PageTitle from "../../components/PageTitle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";


import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import { Avatar, Button } from "@mui/material";

import axios from "axios";
import CircularIndeterminate from "../../components/Loading";

import { useState, useEffect } from "react";
// import classnames from "classnames";
import { Typography } from "../../components/Wrappers/Wrappers";

import { Toolbar, MenuItem } from "@material-ui/core";
import "@material-ui/icons";

// import useStyles from "./styles";

const myStyle = {
  Bodi: {
    position: "absolute",
    padding: 10,
    minWidth: "85%",
    marginTop: "3%vh",
    left: "18%",
    top: "32vh",
  },
  modal: {
    position: "absolute",
    // display: 'flex',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55vw",
    height: "62vh",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "18px",
    boxShadow: 24,
    p: 4,
  },
};

/* const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
}; */
// useEffect(() => {
//   setLoginStatus(res.data.status);
// }, []);

function User(props) {
  const refresh = () => {
    axios
      .get("https://pingup.ajebur.com/api/users/list")
      .then((res) => {
        // console.log(res.data.data);
        setApidata(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [userid, setUserid] = useState(null);
  const [checked, setChecked] = useState(0);
  // const [open, setOpen] = useState(false)

  const [apidata, setApidata] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [open, setOpen] = React.useState(false);

  const [activeuser, setActiveuser] = useState("");

  const handleOpen = (e) => {
    setUserid(e.id);
    setActiveuser(e);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // const [notificationsMenu, setNotificationsMenu] = useState(null);

  //   const [apidata2, setApidata2] = useState("");

  const onSubmit = () => {
    const formData = new FormData();
    formData.append("id", userid);
    formData.append("status", checked);

    axios({
      method: "POST",
      url: "https://pingup.ajebur.com/api/users/status",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => refresh())
      .catch((err) => console.log(err));
    setOpen(false);

    if (checked === 1) {
      toast.success("user Activated", {
        position: "top-right",
      });
    } else {
      toast.error("user Deactivated", {
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    axios
      .get("https://pingup.ajebur.com/api/users/list")
      .then((res) => {
        // console.log(res.data.data);
        setApidata(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle title="Users management" style={{position:"relative",left:30}}/>
        <TextField
          id="input-with-icon-textfield"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icons.SearchOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          style={{ position: "relative", top: "8vh" }}
        />
      </div>
      <div style={myStyle.Bodi}>
        <Paper
          sx={{
            width: "85%",
            overflow: "hidden",
            borderRadius: "20px",
            // position: "absolute",
            // top: "-10vh",
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "85%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    S/N
                  </TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Mobile-Number</TableCell>
                  <TableCell align="center">Status</TableCell>
                  {/* <TableCell align="center">Action</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {apidata === "" ? (
                  <CircularIndeterminate />
                ) : (
                  apidata
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((apidata, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="left">{apidata.name}</TableCell>
                        <TableCell align="center">{apidata.email}</TableCell>
                        <TableCell align="center">{apidata.mobile}</TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: [
                                apidata.status === 1 ? "#8ABE1E" : "red",
                              ],position:"relative",right:23
                            }}
                            onClick={() => handleOpen(apidata)}
                          >
                            {apidata.status === 1 ? "Active" : "Inactive"}
                          </Button>
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={myStyle.modal}>
                              <Typography
                                id="modal-modal-title"
                                variant="h2"
                                component="h2"
                              >
                                User's Details
                              </Typography>
                              <hr />

                              {[activeuser]
                                // .filter((item) => item.id === userId)
                                .map((main, ind) => (
                                  <Box key={ind}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={
                                            main.avatar === "default.png"
                                              ? "https://pingup.ajebur.com/default/" +
                                                main.avatar
                                              : "https://pingup.ajebur.com/profile/" +
                                                main.avatar
                                          }
                                          sx={{
                                            width: 220,
                                            height: 220,
                                            mt: 2,
                                            mb: 2,
                                            marginRight: "-7vw",
                                          }}
                                        />

                                        <Typography
                                          style={{
                                            left: "3vw",
                                            position: "relative",
                                          }}
                                          id="modal-modal-title"
                                          variant="h5"
                                          component="h5"
                                        >
                                          {main.name}
                                        </Typography>
                                      </Box>
                                      <Divider
                                        orientation="vertical"
                                        flexItem
                                      />
                                      <Box style={{ marginLeft: "-9vw" }}>
                                        <Typography>
                                          <strong>Email:</strong> {main.email}
                                        </Typography>
                                        <Typography sx={{ mt: 2 }}>
                                          <strong>mobile:</strong> {main.mobile}
                                        </Typography>
                                        <Typography sx={{ mt: 2 }}>
                                          <strong>Credit:</strong> {main.credit}
                                        </Typography>
                                        <Typography sx={{ mt: 2 }}>
                                          <strong>Address:</strong>{" "}
                                          {main.address}
                                        </Typography>

                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                          <strong>Status:</strong>{" "}
                                          {main.status === 0 &&
                                          checked === false ? (
                                            <Button
                                              color="error"
                                              variant="outlined"
                                            >
                                              Inactive
                                            </Button>
                                          ) : (
                                            <Button
                                              color="success"
                                              variant="outlined"
                                            >
                                              Active
                                            </Button>
                                          )}
                                        </Typography>
                                        <Toolbar />
                                        <Divider />
                                        <Toolbar />

                                        <Box sx={{ mt: 2 }}>
                                          <span style={{ display: "flex" }}>
                                            {main.status === 0 ? (
                                              <h4
                                                style={{
                                                  marginRight: "1.6vw",
                                                }}
                                              >
                                                Activate :
                                              </h4>
                                            ) : (
                                              <h4
                                                style={{
                                                  marginRight: "1.6vw",
                                                }}
                                              >
                                                {" "}
                                                Deactivate :
                                              </h4>
                                            )}
                                            <FormControl>
                                              <InputLabel id="demo-simple-select-label">
                                                Status
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                sx={{
                                                  mr: 4,
                                                  pt: -5,
                                                  height: 43,
                                                  width: 120,
                                                }}
                                                id="demo-simple-select"
                                                value={checked}
                                                label="Status"
                                                onChange={(e) =>
                                                  setChecked(e.target.value)
                                                }
                                              >
                                                <MenuItem value={1}>
                                                  {" "}
                                                  Activate
                                                </MenuItem>
                                                <MenuItem value={0}>
                                                  Deactive
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                            <div>
                                              <Button
                                                variant="contained"
                                                onClick={onSubmit}
                                              >
                                                <Icons.ArrowForward />{" "}
                                              </Button>
                                            </div>
                                          </span>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                ))}
                            </Box>
                          </Modal>{" "}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 200]}
            component="div"
            count={apidata.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}
export default User;
