import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';

function Construction() {
    return (
        <div>
            <PageTitle title='This page is still under construction' />

        </div>

    )
}

export default Construction;
