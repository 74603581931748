import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import PageTitle from "../../components/PageTitle/PageTitle";
import { useState, useEffect } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "@mui/material";

import axios from 'axios'
import CircularIndeterminate from "../../components/Loading";
import { toast } from "react-toastify";




const columns = [
    // { id: "created_at", label: "S/N", minWidth: 150 },



    {
        id: "category",
        label: "Subcategory",
        minWidth: 150,

        format: (value) => value.toLocaleString("en-US"),
    },

    {
        id: "amount",
        label: "Amount",
        minWidth: 150,

        format: (value) => value.toFixed(2),
    },
];

const myStyle = {
    Bodi: {
        position: "absolute",
        padding: 20,
        minWidth: "80vw",
        marginTop: "3%vh",
        left: "18.4%",
        top: "32vh",
    },
    menu: {
        position: "relative",
        top: "2.6vh",
        right: "0.6vw",
        width: "5vw",
        marginLeft: "3vw",
    },
    view: {
        position: "relative",
        top: "3.2vh",
        marginLeft: "3vw",
    },
};




function Provinces() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [country, setCountry] = useState('')
    const [apiData, setApiData] = useState(null)
    const [statesApi, setStatesApi] = useState([])
    const [countryID, setCountryID] = useState(null);



    // const availableState = apiData.find((c) => c.name === country)

    // console.log(availableState)



    // const alert = toast.info('Select Country', {
    //     position: 'top-center'
    // })



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const fetch = async () =>
            await
                axios.get('https://pingup.ajebur.com/api/main/category/income')
                    .then(res =>
                        setApiData(res.data.data),

                    )
                    .catch(err => console.log(err))


        fetch()
    }, [])


    useEffect(() => {
        const stateData = async () => {
            await axios.get(`https://pingup.ajebur.com/api/sub/category/income/${countryID}`)
                .then(res => {

                    setStatesApi(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                });
        }
        stateData()
    }, [countryID])





    const alert = useEffect(() => {

        toast.success('Please Select Category', {
            position: 'top-center'
        })



    }, [])




    const amount = countryID === null ? null :
        (<div>
            {apiData?.filter(item => item.id === countryID).map((arr, index) =>
                (<span key={index}> <h4>Total {arr.category} amount :</h4><h1 >{arr.amount}</h1></span>))}
        </div>)





    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <PageTitle title="Income" />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={country}
                        label="Category"
                        onChange={(e) => setCountry(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {apiData?.map((con, index) => (<MenuItem onClick={() => setCountryID(con.id)} value={con.category} key={index}>{con.category}</MenuItem>))}

                    </Select>

                </FormControl>
            </div>
            <div style={myStyle.Bodi}>
                {amount}
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "20px",
                        // position: "absolute",
                        // top: "-10vh",
                    }}

                >

                    <TableContainer sx={{ maxHeight: 900, maxWidth: 1300 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    statesApi === null ? alert : countryID === null ? <CircularIndeterminate /> : statesApi
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.code}
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ width: 200 }}
                                                            >


                                                                {value === 1.0 ? <Button color='success' variant="outlined">Active</Button> : value && value === countryID ? country : value}




                                                                {/* {column.format && typeof value === "number"
                                                                ? column.format(value)
                                                                : value} */}
                                                            </TableCell>
                                                        );
                                                    })}
                                                    {/* <div style={{ display: "flex" }}>
                                                        <div style={myStyle.view}>
                                                            <IconButton>
                                                                <Icons.RemoveRedEyeOutlined
                                                                    style={{ fontSize: "17px" }}
                                                                />
                                                            </IconButton>
                                                        </div>
                                                        <div style={myStyle.menu}>
                                                            <IconButton>
                                                                <Icons.DeleteOutline />
                                                            </IconButton>
                                                        </div>
                                                    </div> */}
                                                </TableRow>
                                            );
                                        })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 200]}
                        component="div"
                        count={statesApi.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </div>
    );
}
export default Provinces;
