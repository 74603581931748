import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import PageTitle from "../../components/PageTitle";
import InputAdornment from "@mui/material/InputAdornment";
import CircularIndeterminate from "../../components/Loading";

import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

// file upload
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import axios from "axios";

import { useState, useEffect } from "react";
import classnames from "classnames";
import { Typography, Button } from "../../components/Wrappers/Wrappers";

import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  Fab,
  // Link
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

// import useStyles from "./styles";

const myStyle = {
  Bodi: {
    position: "absolute",
    padding: 20,
    minWidth: "80vw",
    marginTop: "3%vh",
    left: "18.4%",
    top: "32vh",
  },
};
const formstyle = {
  position: "relative",
  left: "50vw",
  width: "30vw",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};
function Banners() {
  const [apidata, setApidata] = useState("");
  // const [apidata2, setApidata2] = useState("");
  const handleUpload = () => {
    axios
      .get("https://pingup.ajebur.com/api/slider/list")
      .then((res) => {
        // console.log(res);
        setApidata(res.data.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get("https://pingup.ajebur.com/api/slider/list")
      .then((res) => {
        // console.log(res);
        setApidata(res.data.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(apidata);

  const [title, setTitle] = useState();
  const [urls, setUrls] = useState();
  const [status, setStatus] = useState();
  const [read, setRead] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(undefined);

  // first modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // second modal
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const handleDelete = (e) => {
    axios
      .post("https://pingup.ajebur.com/api/slider/delete", {
        id: e,
      })
      .then(function (response) {
        console.log(response);
        handleUpload();
        handleClose2()
      })
      .catch(function (error) {
        console.log(error);
        handleClose2()
      });
  };
  // end
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [notificationsMenu, setNotificationsMenu] = useState(null);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const imageHandler = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    } else {
      setSelectedFile(e.target.files[0]);
    }

    const file = e.target.files;
    for (let i = 0; i < file.length; i++) {
      let file1 = file[i];
      console.log(file1.name);
      setRead(file1);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const photoFormData = new FormData();
    photoFormData.append("file", read);
    photoFormData.append("title", title);
    photoFormData.append("adlink", urls);
    photoFormData.append("status", status);

    // console.log(photoFormData);
    axios({
      method: "POST",
      url: "https://pingup.ajebur.com/api/slider/add",
      data: photoFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log("yeh we have", res);
        handleUpload();
        handleClose(  )
      })
      .catch((er) => {
        console.log("no data sorry ", er);
      });

    setTitle("");
    setStatus("");
    setRead("");
    setSelectedFile(undefined);
    setPreview(undefined);
   console.log(photoFormData)
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle title="Banners management" />
        <TextField
          id="input-with-icon-textfield"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icons.SearchOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          style={{ position: "relative", top: "8vh" }}
        />
      </div>
      
      <div style={myStyle.Bodi}>
        <Paper
          sx={{
            width: "85%",
            overflow: "hidden",
            borderRadius: "20px",
            // position: "absolute",
            // top: "-10vh",
          }}
        >
          <div style={{ fontSize: 30}}>
        <IconButton onClick={handleOpen}>
          <Icons.CloudUpload style={{ fontSize: 30 }} /><strong style={{fontSize:15}}>UPLOAD</strong>
        </IconButton>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Paper sx={{ width: 800, padding: "20px", marginLeft: 10 }}>
                <form>
                  <div style={{ marginBottom: "5vh" ,display:"flex"}}>
                    <TextField
                      sx={{ mr: 5, width: 200 }}
                      id="outlined-basic"
                      label="Title"
                      // borderColor='green'
                      type="text"
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      variant="outlined"
                    />
                    <TextField
                      sx={{ mr: 5, width: 200 }}
                      id="outlined-basic"
                      label="Url"
                      // borderColor='green'
                      type="text"
                      onChange={(e) => setUrls(e.target.value)}
                      value={urls}
                      variant="outlined"
                    />

                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        sx={{ mr: 5, width: 300 }}
                        id="demo-simple-select"
                        value={status}
                        label="Roles"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value={1}> Active</MenuItem>
                        <MenuItem value={0}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <Box>
                    <div style={{ position: "relative", left: "10vw" }}>
                      {preview === undefined || "" ? (
                        <Icons.Image style={{ fontSize: "20vw" }} />
                      ) : (
                        <img
                          src={preview}
                          alt="ajebuta"
                          style={{
                            width: 200,
                            position: "relative",
                            left: "4vw",
                          }}
                        />
                      )}
                    </div>

                    <IconButton>
                      <label
                        for="file-upload"
                        style={{
                          padding: "9px 15px",
                          cursor: "pointer",
                          display: "inline-block",
                          backgroundColor: "#3CD4A0",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          borderRadius: 7,
                          width: "200px",
                          position: "relative",
                          left: "13.5vw",
                        }}
                      >
                        <input
                          id="file-upload"
                          type="file"
                          style={{ display: "none" }}
                          name="file"
                          onChange={imageHandler}
                        />
                        Upload
                      </label>
                    </IconButton>
                  </Box>
                </form>
              </Paper>
              <Button
                color="success"
                variant="contained"
                style={{ position: "relative", left: "23vw", margin: 10 }}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </Box>
          </Fade>
        </Modal>
      </div>





          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    S/N
                  </TableCell>
                  <TableCell align="right">Image</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apidata === "" ? (
                  <CircularIndeterminate />
                ) : (
                  apidata
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((apidata, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="right">
                          <img
                            src={
                              `https://pingup.ajebur.com/slider/` +
                              apidata.image
                            }
                            alt="ads"
                            style={{ width: 80, height: 50, borderRadius: 10 }}
                          />
                        </TableCell>
                        <TableCell align="right">{apidata.name}</TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: [
                                apidata.status === 1 ? "#8ABE1E" : "red",
                              ],
                            }}
                          >
                            {apidata.status === 1 ? "Active" : "Inactive"}
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <div
                            style={{
                              display: "flex",
                              position: "relative",
                              left: "5vw",
                            }}
                          >
                            <div>
                              <IconButton onClick={handleOpen2}>
                                <Icons.DeleteOutline style={{ fontSize: 20 }} />
                              </IconButton>

                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open2}
                                onClose={handleClose2}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}
                              >
                                <Fade in={open2}>
                                  <Box sx={style2}>
                                    <Paper
                                      sx={{
                                        width: 400,
                                        padding: "20px",
                                        marginLeft: 2,
                                      }}
                                    >
                                      <Typography>
                                        Are you sure you want to delete this?
                                      </Typography>
                                    </Paper>
                                    <Button
                                      color="error"
                                      variant="contained"
                                      style={{
                                        position: "relative",
                                        left: "5vw",
                                        margin: 10,
                                      }}
                                      onClick={handleClose2}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      color="success"
                                      variant="contained"
                                      style={{
                                        position: "relative",
                                        left: "10vw",
                                        margin: 10,
                                      }}
                                      onClick={() => handleDelete(apidata.id)}
                                    >
                                      Ok
                                    </Button>
                                  </Box>
                                </Fade>
                              </Modal>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 200]}
            component="div"
            count={10}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}
export default Banners;
