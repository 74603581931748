import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  //NotificationsNone as NotificationsIcon,

  //  FilterNone as UIElementsIcon,


  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import * as Icons from "@material-ui/icons";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";


// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },

  {
    label: "Services Providers",
    //link: "/app/ui",
    icon: <Icons.Computer />,
    children: [
      { label: "Verification", link: "/app/verification" },
      { label: "Providers", link: "/app/services" },
    ],
  },
  {
    label: "User",
    link: "/app/users",
    icon: <Icons.Group />,

  },

  {
    label: "Locations",
    // link: "/app",
    icon: <Icons.LocationCity />,
    children: [
      { label: "Country List", link: "/app/country" },
      { label: "State List", link: "/app/state" },
      { label: "City List", link: "/app/city" },
    ],
  },



  {
    label: "Categories",
    link: "/app/category",
    icon: <Icons.AppsOutlined />,

  },
  {
    label: "Finance",

    icon: <Icons.AttachMoney />,
    children: [
      { label: "Credit Check", link: "/app/creditcheck" },
      { label: "Income", link: "/app/income" },
      { label: "Wallet history", link: "/app/wallet" },
    ],
  },



  {
    label: "Report",
    link: "/app/construction",
    icon: <Icons.Report />,
  },
  {
    label: "Banner",
    link: "/app/banners",
    icon: <Icons.PhotoLibrary />,
  },
  {
    label: "Settings",
    // link: "",
    icon: <Icons.Settings />,
    children: [
      { label: "Create Admin", link: "/app/createadmin" },
      { label: "Sub Admin", link: "/app/subadmin" },
      { label: "Create Category", link: "/app/createcat" },
    ],
  },

  { type: "divider" },

];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      // style={{ backgroundColor: "active" }}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link, index) => (
          <SidebarLink
            key={index}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
