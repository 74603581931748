import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import axios from "axios";
// import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./rounded512.png";
// import google from "../../images/google.svg";

// context
import {
  useUserDispatch,
  loginUser,

} from "../../context/UserContext";

function Login(props) {
  // useEffect(() => {
  //   localStorage.setItem('login_status',initialState.isAuthenticated);
  // }, [initialState.isAuthenticated]);

  const classes = useStyles();

  // global
  const userDispatch = useUserDispatch();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error1, setError1] = useState(null);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [activeTabId, setActiveTabId] = useState(0);
  const [loginValue, setLoginValue] = useState(null);
  const [passwordValue, setPasswordValue] = useState(null);


  const URL = "https://pingup.ajebur.com/api/admin/login";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    axios({
      method: "POST",
      url: URL,
      data: { email: loginValue, password: passwordValue },
    })
      .then((res) => {
        res.data.status === true || res.data.status === false ? setIsLoading(false) : setIsLoading(true)
        loginUser(
          userDispatch({
            type: "LOGIN_SUCCESS",
            payload: res.data.status,
          }),
          loginValue,
          passwordValue,
          props.history,
        );

      })
      .catch((err) => {
        if (err) {
          setIsLoading(false)
          setError1(true)
        }
      });

  };
  console.log(error2)


  const day = new Date()
  const hrs = day.getHours()


  const greeting = hrs < 12 ? 'Good Morning' : hrs >= 12 && hrs < 17 ? 'Good Afternoon' : 'Good Evening'



  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>Ajebuta Admin</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography constiant="h1" className={classes.greeting}>
                {greeting}, Admin
              </Typography>
              {error2 === true ?

                <Typography color="secondary" className={classes.errorMessage}>
                  Your password or email is not correct :(
                </Typography>
                : null}
              {/* <Fade in={error2}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Your password or email no correct oga try again :(
                </Typography>
              </Fade> */}

              {error1 ?
                <Fade
                  in={error1}
                >
                  <Typography color="secondary" className={classes.errorMessage}>
                    check your internet connection, and try again:(
                  </Typography>
                </Fade> : null}
              {error3 ?
                <Fade in={error3}>
                  <Typography color="primary" className={classes.errorMessage}>
                    Login Sucess:(
                  </Typography>
                </Fade> : null}
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                // value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                // value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    // disabled={
                    //   loginValue.length === 0 || passwordValue.length === 0
                    // }
                    onClick={handleSubmit}
                    constiant="contained"
                    color="primary"
                    size="large"
                    variante="contained"
                    sx={{ backgroundColor: "#8abe1e" }}
                  >
                    Login
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                >
                  Forget Password
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2022 Ajebuta Pingup, All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
