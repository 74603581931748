import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Grid, Typography } from '@material-ui/core';
import axios from 'axios'
import CountUp from 'react-countup'
import Widget from '../../components/Widget/Widget';
import { useState, useEffect } from 'react';

const WalletHistory = () => {
    const [api, setApi] = useState(null)




    const style = {
        minWidth: '100%',
        display: "flex",

        flexGrow: 1,
        // flexDirection: "column",
        // justifyContent: "space-around",
    }

    useEffect(() => {
        const fetch = async () => {
            await
                axios
                    .get("https://pingup.ajebur.com/api/wallet/history")
                    .then((res) => {
                        setApi(res.data)

                        console.log(api.credit)
                    })
                    .catch((err) => {
                        console.log(err);
                    })


        }
        fetch()

    }, []);




    return (
        <div>

            <PageTitle title=" Wallet History" />

            <Grid container style={style} space={2}>
                <Grid item style={{ marginRight: '14vw', height: '25vh' }} lg={3} md={4} sm={6} xs={12}>
                    <Widget
                        title='Credit'

                    >
                        <Typography variant='h1'>
                            <CountUp end={api === null ? 13000 : api.credit} duration={2} />
                        </Typography>

                    </Widget>
                </Grid>


                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Widget
                        title='Debit'
                    // style={style}
                    >
                        <Typography variant='h1'>
                            <CountUp end={api === null ? 0 : api.debit} duration={2} />
                        </Typography>

                    </Widget>
                </Grid>






            </Grid>






        </div>

    );
};

export default WalletHistory;
