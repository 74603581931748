import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import PageTitle from "../../components/PageTitle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularIndeterminate from "../../components/Loading";

// import axios from "axios";

import { useState } from "react";
// import classnames from "classnames";
import { Button } from "../../components/Wrappers/Wrappers";

import {
  // AppBar,
  // Toolbar,
  IconButton,
  // InputBase,
  // Menu,
  // MenuItem,
  // Fab,
  // Link
} from "@material-ui/core";
// import {
//   Menu as MenuIcon,
//   MailOutline as MailIcon,
//   NotificationsNone as NotificationsIcon,
//   Person as AccountIcon,
//   Search as SearchIcon,
//   Send as SendIcon,
//   ArrowBack as ArrowBackIcon,
// } from "@material-ui/icons";

// import useStyles from "./styles";

const myStyle = {
  Bodi: {
    position: "absolute",
    padding: 20,
    minWidth: "80vw",
    marginTop: "3%vh",
    left: "18.4%",
    top: "32vh",
  },
};

function Specialization(props) {

  const hookholder = props.hookholder
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle title="Specialization management" />
        <TextField
          id="input-with-icon-textfield"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icons.SearchOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          style={{ position: "relative", top: "8vh" }}
        />
      </div>
      <div style={myStyle.Bodi}>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "20px",
            // position: "absolute",
            // top: "-10vh",
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    S/N
                  </TableCell>
                  <TableCell align="right">Category-Name</TableCell>
                  <TableCell align="right">Specialization-Name</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hookholder === "" ? (
                  <CircularIndeterminate />
                ) : (
                  hookholder.map((apidata, index) => (
                    <TableRow
                      key={apidata.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="right">{apidata.category_id === 1 ? "General Service Provider" : apidata.category_id === 2 ? "Technical Service Provider" : apidata.category_id === 3 ? "Quick Service Provider" : apidata.category_id === 4 ? "Professional Service Provider" : apidata.category_id === 5 ? "Vendor Services" : apidata.category_id === 6 ? "Medical Service Provider" : null}</TableCell>
                      <TableCell align="right">{apidata.name}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: [apidata.status === 1 ? "green" : "red"] }}
                        >
                          {apidata.status === 1 ? "Verified" : "Unverified"}
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <div
                          style={{
                            display: "flex",
                            // position: "relative",
                            // left: "7.5vw",
                          }}
                        >

                          <div>
                            <IconButton>
                              <Icons.DeleteOutline style={{ fontSize: 20 }} />
                            </IconButton>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 200]}
            component="div"
            count={hookholder.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}
export default Specialization;
