import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as Icons from "@material-ui/icons";
import { IconButton } from '@material-ui/core';
import PageTitle from "../../components/PageTitle";
import { useState, useEffect } from "react";
import { Button, Avatar } from "@mui/material";
import axios from 'axios'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularIndeterminate from "../../components/Loading";
import { toast } from 'react-toastify'




const columns = [

  // { id: "avatar", label: "Avatar", minWidth: 150 },

  { id: "name", label: "Name", minWidth: 150 },

  {
    id: "email",
    label: "Email",
    minWidth: 150,

    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "mobile", label: "Number", minWidth: 150 },
  { id: "address", label: "Address", minWidth: 150 },
  { id: "credit", label: "Credit", minWidth: 150 },
  {
    id: "verification",
    label: "Verification",
    minWidth: 10,

    format: (value) => value.toFixed(2),
  },
];

const myStyle = {
  Bodi: {
    position: "absolute",
    padding: 20,
    minWidth: "80vw",
    marginTop: "3%vh",
    left: "18.4%",
    top: "32vh",
  },
  menu: {
    position: "relative",
    top: "2.6vh",
    right: "0.6vw",
    width: "5vw",
    marginLeft: "3vw",
  },
  view: {
    position: "relative",
    top: "3.2vh",
    marginLeft: "1.5vw",
    marginRight: "1.5vw",
  },
  modal: {
    position: 'absolute',
    // display: 'flex',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55vw',
    height: '62vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '18px',
    boxShadow: 24,
    p: 4,
  }



};




function Verification() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [apiData, setApiData] = useState('')
  const [open, setOpen] = useState(false)
  const [providerId, setProviderId] = useState(null)
  const [checked, setChecked] = useState('')





  const onSubmit = () => {

    const formData = new FormData()
    formData.append('id', providerId)
    formData.append('verification', checked)

    axios({
      method: 'POST',
      url: 'https://pingup.ajebur.com/api/providers/verification/update',
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then(res => console.log(res))
      .catch(err => console.log(err))




    setOpen(false)



    if (checked === 1) {
      toast.success('Provider Verified', {
        position: 'top-right'
      })
    } else {

      toast.warning('Provider Unverify', {
        position: 'top-right'
      })
    }




  }









  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    axios.get('https://pingup.ajebur.com/api/providers/verification/list')
      .then(res =>
        setApiData(res.data.data),

      )
      .catch(err => console.log(err))
  }, [])

  return (
    <div>
      <PageTitle title="Providers Verification" />
      <div style={myStyle.Bodi}>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "20px",
            // position: "absolute",
            // top: "-10vh",
          }}
        >
          <TableContainer sx={{ maxHeight: 1000, maxWidth: 1200 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {apiData === '' ? (
                  <CircularIndeterminate />) : (apiData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover

                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ width: 700 }}
                              >

                                {value === 0 || value === 2 ?
                                  <Button color='error' variant="outlined">Unveried</Button>
                                  : value === 1 ? <Button color='success' variant="outlined">Verified</Button> : value}

                              </TableCell>
                            );
                          })}

                          <div style={myStyle.view}>

                            <IconButton
                              onClick={() => {
                                setProviderId(row.id)
                                setOpen(true)
                              }}
                            >
                              <Icons.RemoveRedEyeOutlined
                                style={{ fontSize: "17px" }}

                              />
                            </IconButton>

                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={myStyle.modal}>
                                <Typography id="modal-modal-title" variant="h4" component="h4">
                                  Provider's Details
                                </Typography>
                                {/* <Divider /> */}
                                <hr />

                                {apiData.filter((item) => item.id === providerId).map((main, ind) => (
                                  <Box key={ind}>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <Box
                                      >
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={`https://pingup.ajebur.com/profile/${main.avatar}`}
                                          sx={{ width: 220, height: 220, mt: 2, mb: 2, marginRight: '-7vw' }}
                                        />


                                        <Typography style={{ left: '3vw', position: 'relative' }} id="modal-modal-title" variant="h5" component="h5">
                                          {main.name}
                                        </Typography>
                                      </Box>
                                      <Divider orientation="vertical" flexItem />
                                      <Box style={{ marginLeft: '-9vw' }}>

                                        <Typography >
                                          <strong>Email:</strong> {main.email}
                                        </Typography>
                                        <Typography sx={{ mt: 2 }}>
                                          <strong>mobile:</strong> {main.mobile}
                                        </Typography>
                                        <Typography sx={{ mt: 2 }}>
                                          <strong>Credit:</strong> {main.credit}
                                        </Typography>
                                        <Typography sx={{ mt: 2 }}>
                                          <strong>Address:</strong> {main.address}
                                        </Typography>

                                        <Typography sx={{ mt: 2, mb: 2 }}>
                                          <strong>Status:</strong> {main.verification === 0 || main.verification === 2 ?
                                            <Button color='error' variant="outlined">UnVerified</Button> :
                                            <Button color='success' variant="outlined">Verified</Button>}
                                        </Typography>
                                        <Divider />
                                        <Box sx={{ mt: 2, position: 'relative' }}>
                                          <span style={{ display: 'flex' }}>
                                            {main.verification === 0 || main.verification === 2 ? <h4 style={{ marginRight: '1.6vw' }} >Verify :</h4> :
                                              <h4 style={{ marginRight: '1.6vw' }}> Unverify :</h4>}
                                            <FormControl>
                                              <InputLabel id="demo-simple-select-label">Verification</InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                sx={{ mr: 4, pt: -5, height: 43, width: 120 }}
                                                id="demo-simple-select"
                                                value={checked}
                                                label="Verification Status"
                                                onChange={(e) => setChecked(e.target.value)}
                                              >
                                                <MenuItem value={1}> Verify</MenuItem>
                                                <MenuItem value={0}>Unverify</MenuItem>

                                              </Select>
                                            </FormControl>
                                            <div>
                                              <Button variant='contained' onClick={onSubmit}><Icons.ArrowForward /> </Button>
                                            </div>
                                          </span>
                                        </Box>



                                      </Box>


                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </Modal>

                          </div>


                        </TableRow>
                      );
                    }))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 200]}
            component="div"
            count={apiData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}
export default Verification;