import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Paper } from "@mui/material";
import { useState } from "react";
import axios from "axios";
//import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const formstyle = {

    position: 'relative',
    left: '43vw'
}


function CreateAdmin() {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [mobile, setMobile] = useState()
    const [roles, setRoles] = useState()
    const [password, setPassword] = useState()
    const [des, setDes] = useState()




    console.log(roles)

    const onSubmit = (e) => {
        // e.preventDefault

        const formData = new FormData()
        formData.append('name', name)
        formData.append('email', email)
        formData.append('mobile', mobile)
        formData.append('roles', roles)
        formData.append('password', password)
        formData.append('designation', des)
        axios({
            method: 'POST',
            url: 'http://pingup.ajebur.com/api/create/admin',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(res => console.log(res))
            .catch(err => console.log(err))

    }


    return (

        <div>
            <PageTitle title="Add Credit" />
            <Paper sx={{ width: 800, padding: '20px', margin: 'auto 18%' }}>
                <form>

                    <div style={{ marginBottom: '5vh', }} >
                        <TextField
                            sx={{ mr: 5, width: 300 }}
                            id="outlined-basic"
                            label="Occupation"
                            type='text'
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            variant="outlined" />

                        <TextField sx={{ width: 400 }}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            id="outlined-basic" type='email'
                            label="Credit Value" variant="outlined" />
                    </div>
                    <div style={{ marginBottom: '5vh', }} >
                        <TextField
                            sx={{ mr: 5, width: 300 }}
                            id="outlined-basic"
                            label="country"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            variant="outlined" />

                        <TextField sx={{ width: 400 }}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            id="outlined-basic" type='email'
                            label="Currency" variant="outlined" />
                    </div>
                    <div style={{ marginBottom: '5vh' }}>

                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                sx={{ mr: 5, width: 300 }}
                                id="demo-simple-select"
                                value={roles}
                                label="Status"
                                onChange={(e) => setRoles(e.target.value)}
                            >
                                <MenuItem value={1}> Active</MenuItem>
                                <MenuItem value={0}>Inactive</MenuItem>

                            </Select>
                        </FormControl>


                        <TextField sx={{ width: 400 }} id="outlined-basic"
                            label="Free Credit" variant="outlined"
                            onChange={(e) => setDes(e.target.value)}
                            value={des} />
                    </div>
                    <div style={{ marginBottom: '5vh' }}>
                        <TextField sx={{ mr: 5, width: 300 }} id="outlined-basic"
                            label="Minimum Value" variant="outlined"
                            onChange={(e) => setMobile(e.target.value)}
                            value={mobile} />

                        <TextField id="outlined-basic" sx={{ width: 400 }}
                            label="Maximum Value" variant="outlined"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password} />
                    </div>
                    <Button onClick={onSubmit} color='success' sx={formstyle} variant="contained">Submit  </Button>
                </form>
            </Paper>


        </div>
    );
}

export default CreateAdmin;

