import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Grid, Typography } from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Paper } from "@mui/material";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularIndeterminate from "../../components/Loading";

import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  Fab,
  // Link
} from "@material-ui/core";

const formstyle = {
  position: "relative",
  left: "16.2vw",
  bottom: "3vh",
};

export default function CreateCategory() {

    const [apidata, setApidata] = useState("");
  
    useEffect(() => {
      axios
        .get("https://pingup.ajebur.com/api/category/list")
        .then((res) => {
          // console.log(res.data.data.maincat);
          setApidata(res.data.data);
          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);


console.log(apidata)
  const [categoryname, setCategoryname] = useState();

  const [subcategoryname, setSubcategoryname] = useState();
  const [cat, setCat] = useState();
  const [rate, setRate] = useState();
  const [status, setStatus] = useState();
  const [catid, setCatid] = useState();
  const [recharge, setRecharge] = useState();
  const [keyword, setKeyword] = useState();

//   console.log(catid);

  const onSubmit1 = (e) => {
    // e.preventDefault
    if (categoryname === "") {
      toast.error("fields are required", {
        position: "top-center",
      });
    } else {
      const formData = new FormData();
      formData.append("name", categoryname);

      axios({
        method: "POST",
        url: "https://pingup.ajebur.com/api/main-category/add",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            toast.success("category Created", {
              position: "top-center",
            });
          } else {
            toast.error("category was'nt Created", {
              position: "top-center",
            });
          }
        })
        .catch((err) =>
          err
            ? toast.error("something went wrong", {
                position: "top-center",
              })
            : null,
        );

      setCategoryname("");
    }
  };
  const onSubmit = (e) => {
    // e.preventDefault
    if (subcategoryname === "" || rate === "") {
      toast.error("All fields are required", {
        position: "top-center",
      });
    } else {
      const formData = new FormData();
      formData.append("name", subcategoryname);
      formData.append("rate", rate);
      formData.append("recharge", recharge);
      formData.append("status", status);
      formData.append("keyword", keyword);
      formData.append("category_id", cat);
      axios({
        method: "POST",
        url: "http://pingup.ajebur.com/api/sub-category/add",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            toast.success("Sub-category Created", {
              position: "top-center",
            });
          } else {
            toast.error("Sub-category was'nt Created", {
              position: "top-center",
            });
          }
        })
        .catch((err) =>
          err
            ? toast.error("something went wrong", {
                position: "top-center",
              })
            : null
        );

      setSubcategoryname("");
      setRate("");
      setKeyword("");
      setStatus("");
      setRecharge("");
      setCatid("");
      setCat("");
    }
  };

  return (
    <div>
      <PageTitle title="create categories" />
      <div style={{ display: "flex" }}>
        <Paper sx={{ width: 400, padding: "20px", margin: "auto 4%" }}>
          <form>
            <div style={{ marginBottom: "5vh" }}>
              <TextField
                sx={{ mr: 5, width: 300 }}
                id="outlined-basic"
                label="Category"
                error={categoryname === ""}
                helperText={categoryname === "" ? "Empty field!" : " "}
                type="text"
                onChange={(e) => setCategoryname(e.target.value)}
                value={categoryname}
                variant="outlined"
              />
            </div>

            <div>
              <Button
                onClick={onSubmit1}
                color="success"
                sx={{ position: "relative" }}
                variant="contained"
              >
                Submit{" "}
              </Button>
            </div>
          </form>
        </Paper>
        <Toolbar />
        <Paper sx={{ width: 750, padding: "20px" }}>
          <form>
            <div style={{ marginBottom: "5vh" }}>
              <TextField
                sx={{ mr: 5, width: 300 }}
                id="outlined-basic"
                label="Sub-category categoryname"
                error={subcategoryname === ""}
                helperText={categoryname === "" ? "Empty field!" : " "}
                type="text"
                onChange={(e) => setSubcategoryname(e.target.value)}
                value={categoryname}
                variant="outlined"
              />

              <TextField
                sx={{ width: 300 }}
                onChange={(e) => setRate(e.target.value)}
                value={rate}
                error={rate === ""}
                helperText={rate === "" ? "Empty field!" : " "}
                id="outlined-basic"
                type="number"
                label="Rate"
                variant="outlined"
              />
            </div>
            <div style={{ marginBottom: "5vh" }}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  sx={{ mr: 5, width: 300 }}
                  id="demo-simple-select"
                  error={status === ""}
                  helperText={status === "" ? "Empty field!" : " "}
                  value={status}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={1}>Active </MenuItem>
                  <MenuItem value={2}>Inactive</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  sx={{ mr: 5, width: 300 }}
                  id="demo-simple-select"
                  error={catid === ""}
                  helperText={catid === "" ? "Empty field!" : " "}
                  value={catid}
                  label="Categories"
                  onChange={(e) => setCatid(e.target.value)}
                >
                    {apidata===""?(<CircularIndeterminate/>):(
                        apidata.map((apid,index)=>(
                            <MenuItem onClick={() => setCat(apid.id)} key={index} value={apid.name}> {apid.name}</MenuItem>                        ))
                    )}
                </Select>
              </FormControl>
            </div>
            <div style={{ marginBottom: "5vh" }}>
              <TextField
                sx={{ mr: 5, width: 300 }}
                id="outlined-basic"
                label="Recharge"
                variant="outlined"
                type="number"
                error={recharge === ""}
                helpertext={recharge === "" ? "Empty field!" : " "}
                onChange={(e) => setRecharge(e.target.value)}
                value={recharge}
              />
              <TextField
                id="outlined-basic"
                sx={{ width: 300 }}
                type="text"
                label="Keyword"
                variant="outlined"
                error={keyword === ""}
                helpertext={keyword === "" ? "Empty field!" : " "}
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
            </div>

            <Button
              onClick={onSubmit}
              color="success"
              sx={formstyle}
              variant="contained"
            >
              Submit{" "}
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
}
