import React from "react";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();


const initialState={
  isAuthenticated:null
};


function userReducer(state, action) {
  
  localStorage.setItem("login_status",action.payload)

  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: action.payload};
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated:action.payload};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
// localStorage.setItem("login_status",initialState.isAuthenticated)

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {

    isAuthenticated: !!localStorage.getItem("login_status"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}


// ###########################################################


function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);

  if (!!login && !!password) {
    setTimeout(() => {
      localStorage.setItem('login_status', 1)
      setError(null)
      dispatch({ type: 'LOGIN_SUCCESS' })

      history.push('/app/dashboard')
    }, 2000);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("login_status");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
export { UserProvider, useUserState, useUserDispatch, loginUser, signOut,userReducer };
